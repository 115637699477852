type BiographyBlock = {
  label: string;
  value: string;
  faqs: {
    id: string;
    question: string;
    answer: React.ReactNode;
  }[];
};

export function getBiography(_rawBiography: any) {
  if (!_rawBiography) {
    return { licensedIn: null, languages: null, faqs: [] };
  }
  const { licensedIn, languages, faqs } = _rawBiography.reduce(
    (prev: BiographyBlock, curr: any) => {
      switch (curr.label) {
        case 'Language Proficiencies':
          return { ...prev, languages: curr.content };
        case 'Licensed to practice in':
          return { ...prev, licensedIn: curr.content };
        case 'Training Institutions':
        case 'What I’m most excited to bring to Parsley Health members':
        case 'Ask me about':
        case 'Most likely to find me':
        case 'Clinical Expertise':
          return {
            ...prev,
            faqs: [
              ...prev.faqs,
              {
                id: curr._key,
                question: curr.label,
                answer: curr.content
              }
            ]
          };
        default:
          return prev;
      }
    },
    {
      licensedIn: '',
      languages: '',
      faqs: []
    }
  );
  return { licensedIn, languages, faqs };
}
