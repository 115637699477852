import React from 'react';
import { graphql } from 'gatsby';
import { PapiProvider, SanityBlogPost } from '../../../graphql-types';
import { TDoctorProfiles } from '../../lib/use-doctors';
import { getBiography } from '../../lib/get-provider-biography';
import portabletextToPlainText from '../../lib/portabletext-to-plain-text';
import { PapiProviderType } from '../../types/papi-global-types';

import {
  Layout,
  Module,
  Faq,
  ProviderProfileInfo,
  FeaturedArticles,
  ProviderCarousel
} from '..';

interface ProviderPageTemplateProps {
  data: {
    profile: PapiProvider;
    articles: {
      edges: {
        node: SanityBlogPost;
      }[];
    };
    otherDoctors: TDoctorProfiles;
  };
}

const getOtherProvidersCopy = (isHealthCoach: boolean) => {
  if (isHealthCoach) {
    return {
      headline: 'Partners with these clinicians',
      description:
        'Our health coaches work closely with a select group of Parsley clinicians to provide insightful, personalized care.'
    };
  }
  return {
    headline: 'Partners with these health coaches',
    description:
      'Our clinicians work with a handpicked team of coaches to ensure seamless, personalized care for you.'
  };
};

export default function Provider({
  data: {
    profile,
    articles: { edges: articles },
    otherDoctors
  }
}: ProviderPageTemplateProps) {
  const { isTakingNewPatients, type, sanity } = profile;
  const {
    _rawBackground,
    _rawInterests,
    _rawBiography,
    authorByLine,
    profileImage,
    role
  } = sanity;
  const PlainTextBackground = _rawBackground
    ? portabletextToPlainText(_rawBackground)
    : null;
  const PlainTextInterests = _rawInterests
    ? portabletextToPlainText(_rawInterests)
    : null;

  const { licensedIn, languages, faqs } = getBiography(_rawBiography);
  const isHealthCoach = type === PapiProviderType.HEALTH_COACH;
  const otherProvidersCopy = getOtherProvidersCopy(isHealthCoach);
  const otherProvidersFiltered = otherDoctors.nodes.filter(p =>
    isHealthCoach
      ? p.type !== PapiProviderType.HEALTH_COACH
      : p.type === PapiProviderType.HEALTH_COACH
  );

  return (
    <Layout>
      <Module>
        <ProviderProfileInfo
          name={authorByLine}
          role={role}
          acceptingPatients={isTakingNewPatients}
          // pronouns="Pending from sanity"
          pronouns=""
          licensedIn={licensedIn}
          languageProficiencies={languages}
          clinicalExpertise={PlainTextInterests}
          bio={PlainTextBackground}
          image={profileImage}
          ctaTrackingLabel="All clinicians button on provider profile module"
        />
      </Module>
      {faqs.length > 0 && (
        <Module>
          <Faq faqs={faqs} />
        </Module>
      )}
      {otherProvidersFiltered.length > 0 && (
        <Module>
          <ProviderCarousel
            {...otherProvidersCopy}
            providers={otherProvidersFiltered}
          />
        </Module>
      )}
      {articles.length > 0 && (
        <Module>
          <FeaturedArticles
            articles={articles.map(i => ({ ...i.node }))}
            headline={{
              text: `Written by ${authorByLine}`,
              variant: 'h3a',
              align: 'left'
            }}
          />
        </Module>
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  fragment PapiProviderProfile on PapiProvider {
    firstName
    lastName
    type
    deleted
    isTakingNewPatients
    centers {
      center {
        id
        state
        city
        isVirtual
      }
    }
    sanityID
    sanity {
      _id
      authorByLine
      featured
      hideinWebsite
      role
      promoCode
      slug {
        current
      }
      background {
        children {
          text
        }
      }
      _rawBio
      _rawBackground
      _rawInterests
      _rawBiography
      profileImage {
        _type
        ...SanityImageFragment
      }
    }
    pod {
      id
      name
    }
  }
  query ProviderPageTemplateQuery(
    $slug: String
    $sanityID: String
    $pod: String
  ) {
    profile: papiProvider(sanity: { slug: { current: { eq: $slug } } }) {
      ...PapiProviderProfile
    }
    articles: allSanityBlogPost(filter: { author: { id: { eq: $sanityID } } }) {
      edges {
        node {
          ...RelatedPost
        }
      }
    }
    otherDoctors: allPapiProvider(
      filter: {
        sanityID: { ne: $sanityID }
        sanity: {
          slug: { current: { ne: null } }
          hideinWebsite: { ne: true }
          role: { regex: "/Doctor|Health Coach/" }
        }
        pod: { id: { eq: $pod } }
      }
    ) {
      nodes {
        ...PapiProviderProfile
      }
    }
  }
`;
